<template>
  <div class="latest">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      v-if="dataList.length > 0"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoadMore"
      >
        <div class="box" v-for="(item, index) in dataList" :key="index">
          <div class="titleBox">
            <div class="tigs1">
              <img
                :src="`${publickPath}/static/img/icons/xiaoquxiaoxi.png`"
                mode="scaleToFill"
              />小区公告
            </div>
            <!-- <div class="tigs2">
          <img
            :src="`${publickPath}/static/img/icons/xitongxiaoxi.png`"
            mode="scaleToFill"
          />系统消息
        </div> -->
            <div class="title">{{ item.title }}</div>
          </div>
          <!-- <div class="text" v-html="item.informationContent">
            {{ item.informationContent }}
          </div> -->
          <div class="more" @click="goDetail(item.id)">
            <div class="timeFont">{{ item.updateTime }}</div>
            <div class="img-arrow">
              <img :src="`${publickPath}static/img/photo/arrow-right.png`" />
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <no-data v-if="dataList.length == 0"></no-data>
  </div>
</template>

<script>
import api from '@/api';
import noData from '../../components/no-data.vue';
import { Toast } from 'vant';
export default {
  name: 'News',
  components: {
    noData,
  },
  data() {
    return {
      publickPath: process.env.BASE_URL,
      params: {
        pageNum: 1,
        pageSize: 10,
        siteId: this.$route.query.siteId,
      },
      dataList: [],
      isLoading: false,
      loading: false,
      finished: false,
      totalPages: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
        this.params.pageNum = 1;
        this.finished = false;
        this.getList();
      }, 1000);
    },
    // 加载更多
    async onLoadMore() {
      if (this.params.pageNum < this.totalPages) {
        this.params.pageNum += 1;
        this.getList();
      }
      if (this.params.pageNum == this.totalPages) {
        this.finished = true;
      }
    },
    // 获取列表数据
    async getList() {
      await api
        .getAnnouncementList(this.params)
        .then((res) => {
          this.totalPages = res.data.totalPages;
          if (this.params.pageNum == 1) {
            this.dataList = res.data.content;
          } else {
            this.dataList = [...this.dataList, ...res.data.content];
          }
        })
        .catch(() => {});
      this.loading = false;
    },
    // 页面跳转
    goDetail(id) {
      this.$router.push({
        path: '/latest/detail',
        query: { id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.latest {
  padding: 0 0.9375rem;
  background-color: #fbfcff;
  .box {
    margin-top: 0.625rem;
    padding: 0 0.9375rem;
    background: #ffffff;
    box-shadow: 0 0.21875rem 0.6563rem 0 rgba(188, 188, 188, 0.11);
    border-radius: 0.4688rem;
    .titleBox {
      display: flex;
      align-items: center;
      padding: 0.625rem 0.9375rem 0;
      .tigs1 {
        width: 4.6875rem;
        height: 1.375rem;
        background: linear-gradient(
          -90deg,
          rgba(70, 209, 90, 0.12),
          rgba(111, 223, 44, 0.12)
        );
        border-radius: 0.4375rem 0 0.4375rem 0;
        font-size: 0.75rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #46d15a;
        line-height: 1.375rem;
        text-align: center;
        img {
          margin-right: 0.1875rem;
          width: 0.7813rem;
          height: 0.625rem;
        }
      }
      .tigs2 {
        width: 4.6875rem;
        height: 1.375rem;
        background: linear-gradient(
          -90deg,
          rgba(56, 104, 255, 0.12),
          rgba(107, 166, 255, 0.12)
        );
        border-radius: 0.4375rem 0 0.4375rem 0;
        font-size: 0.75rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3868ff;
        line-height: 1.375rem;
        text-align: center;
        img {
          margin-right: 0.1875rem;
          width: 0.6875rem;
          height: 0.75rem;
          vertical-align: middle;
        }
      }
      .title {
        margin-left: 0.3125rem;
        font-size: 0.75rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }
    .text {
      margin-top: 0.7813rem;
      padding: 0 0.9375rem;
      font-size: 0.8125rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #9b9ba3;
      line-height: 1.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -webkit-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .more {
      margin-top: 0.7188rem;
      padding: 0 0.9375rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 2.0625rem;
      border-top: 0.0625rem solid #f6f6fa;
      .timeFont {
        font-size: 0.8125rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9b9ba3;
      }
      .img-arrow {
        width: 0.3125rem;
        margin-left: 3rem;
        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
