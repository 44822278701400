<template>
  <div class="no-data">
    <img class="bg" src="../../public/static/img/no-data.png" />
    <div class="word">暂无数据</div>
    <van-button class="btn" @click="toIndex" type="primary"
      >返回首页</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toIndex() {
      this.$router.push({ path: '/main' });
    },
  },
};
</script>

<style lang="less" scoped>
.no-data {
  height: 100vh;
  text-align: center;
  img {
    width: 14.5938rem;
    height: 13.0938rem;
    margin-top: 5rem;
  }
  .word {
    font-size: 0.9375rem;
    color: #999999;
    margin-top: 1.4375rem;
  }
  .btn {
    margin: 2.0938rem auto 0;
    width: 14.5938rem;
    height: 2.8125rem;
    background-color: #336df3;
    border-radius: 0.4063rem;
    line-height: 2.8125rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: #ffffff;
    border: none !important;
  }
}
</style>
